<template lang="pug">
  div
    div.tab__container
      div(
        v-for="(tab, index) in tabs"
        :key="index"
        class="tab"
        @click="activateTab(index)"
        :class="{ active: activeTab === index }"
      ) {{ tab.title }}
    div.tab-content
      div(
        v-for="(tab, index) in tabs"
        :key="index"
        v-show="activeTab === index"
      )
        div.price__container
          div(
            v-for="(item, index) in products[index]"
            :key="index"
          ).price__item
            div.price__img
              div.img__wrapp
                img(:src="item.image" alt="starlink")
            div.price__content
              h4.price__subtitle {{ item.title }}
              div.price__desc
                ul.price__list
                  li(
                    v-for="(feature, featureIndex) in item.features"
                    :key="featureIndex"
                  ).price__entity {{ feature }}
                ul(
                  v-if="item.optional"
                  :class="item.color"
                ).price__list
                  li.price__entity
                    span На вибір:
                    ul
                      li(
                        v-for="(option, optionIndex) in item.optional"
                        :key="optionIndex"
                      ) {{ option }}
                    li.price__entity.custom__entity
                      | Кастомізація під колір авто
            div.price__cost-wrapp
              div.price__cost {{ item.price }}
              router-link.link.price__link(to="", v-scroll-to="'#steps5'") Замовити
</template>

<script>
// constans
import { products } from '@/configs/constants'

export default {
  name: 'PriceBlock',
  data () {
    return {
      activeTab: 0,
      tabs: [
        { title: 'Starlink Internet Satellite Dish Kit V2-3' },
        { title: 'Starlink Internet Satellite Dish Kit V1' }
      ],
      products: products
    }
  },
  methods: {
    activateTab (index) {
      this.activeTab = index
    }
  }
}
</script>
