// images
import Kit6 from '@/assets/images/kits/6.png'
import Kit7 from '@/assets/images/kits/7.png'
import Kit8 from '@/assets/images/kits/8.png'
import Kit9 from '@/assets/images/kits/9.png'
import Kit10 from '@/assets/images/kits/10.png'
import Kit1 from '@/assets/images/kits/1.png'
import Kit2 from '@/assets/images/kits/2.png'
import Kit3 from '@/assets/images/kits/3.png'
import Kit4 from '@/assets/images/kits/4.png'
import Kit5 from '@/assets/images/kits/5.png'

export const products = [
  [
    {
      image: Kit6,
      title: 'Basic',
      color: 'basic',
      features: [
        'Аеродинамічний корпус',
        'Магніти: 4 шт.',
        'Герметизація роз\'ємів',
        'Часткове армування кабелю'
      ],
      optional: [
        'Автомобільний інвертор',
        'Живлення від 12V'
      ],
      price: '250 $'
    },
    {
      image: Kit7,
      title: 'Standart',
      color: 'standart',
      features: [
        'Аеродинамічний корпус',
        'Магніти: 8 шт.',
        'Герметизація роз\'ємів',
        'Подовжене армування кабелю'
      ],
      optional: [
        'Автомобільний інвертор',
        'Живлення від 12V'
      ],
      price: '350 $'
    },
    {
      image: Kit8,
      title: 'Advanced',
      color: 'advanced',
      features: [
        'Аеродинамічний корпус',
        'Магніти: 8 шт.',
        'Герметизація роз\'ємів',
        'Подовжене армування кабелю'
      ],
      optional: [
        'Автомобільний інвертор',
        'Живлення від 12V'
      ],
      price: '500 $'
    },
    {
      image: Kit9,
      title: 'Premium',
      color: 'premium',
      features: [
        'Starlink Internet Satellite Dish Kit v2',
        'Аеродинамічний корпус',
        'Магніти: 8 шт.',
        'Герметизація роз\'ємів',
        'Подовжене армування кабелю'
      ],
      optional: [
        'Автомобільний інвертор',
        'Живлення від 12V'
      ],
      price: '600 $'
    },
    {
      image: Kit10,
      title: 'Starlink + Advanced',
      color: 'starlink',
      features: [
        'Starlink Internet Satellite Dish Kit v2',
        'Аеродинамічний корпус',
        'Магніти: 8 шт.',
        'Герметизація роз\'ємів',
        'Подовжене армування кабелю'
      ],
      optional: [
        'Автомобільний інвертор',
        'Живлення від 12V'
      ],
      price: '1100 $'
    }
  ],
  [
    {
      image: Kit1,
      title: 'Basic',
      color: 'basic',
      features: [
        'Starlink Internet Satellite Dish Kit v1',
        'Аеродинамічний корпус',
        'Магніти: 4 шт.',
        'Герметизація роз\'ємів',
        'Часткове армування кабелю'
      ],
      optional: [
        'Автомобільний інвертор',
        'Живлення від 12V'
      ],
      price: '250 $'
    },
    {
      image: Kit2,
      title: 'Standart',
      color: 'standart',
      features: [
        'Starlink Internet Satellite Dish Kit v1',
        'Аеродинамічний корпус',
        'Магніти: 8 шт.',
        'Герметизація роз\'ємів',
        'Подовжене армування кабелю'
      ],
      optional: [
        'Автомобільний інвертор',
        'Живлення від 12V'
      ],
      price: '350 $'
    },
    {
      image: Kit3,
      title: 'Advanced',
      color: 'advanced',
      features: [
        'Starlink Internet Satellite Dish Kit v1',
        'Аеродинамічний корпус',
        'Магніти: 8 шт.',
        'Герметизація роз\'ємів',
        'Подовжене армування кабелю'
      ],
      optional: [
        'Автомобільний інвертор',
        'Живлення від 12V'
      ],
      price: '500 $'
    },
    {
      image: Kit4,
      title: 'Premium',
      color: 'premium',
      features: [
        'Starlink Internet Satellite Dish Kit v1',
        'Аеродинамічний корпус',
        'Магніти: 8 шт.',
        'Герметизація роз\'ємів',
        'Подовжене армування кабелю'
      ],
      optional: [
        'Автомобільний інвертор',
        'Живлення від 12V'
      ],
      price: '600 $'
    },
    {
      image: Kit5,
      title: 'Starlink + Advanced',
      color: 'starlink',
      features: [
        'Starlink Internet Satellite Dish Kit v1',
        'Аеродинамічний корпус',
        'Магніти: 8 шт.',
        'Герметизація роз\'ємів',
        'Подовжене армування кабелю'
      ],
      optional: [
        'Автомобільний інвертор',
        'Живлення від 12V'
      ],
      price: '1100 $'
    }
  ]
]
